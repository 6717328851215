import './App.css';
import logo from './assets/logo.png'
import * as MaterialIcons from 'react-icons/md'
import { IconType } from 'react-icons/lib';
import React from 'react'
import API from './api/APIClient'
import { Shvush, metadata, selection } from './metadata'

const days = [`א'`, `ב'`, `ג'`, `ד'`, `ה'`, `ו'`, `ז'`, `ח'`, `ט'`, `י'`, `י"א`, `י"ב`, `י"ג`, `י"ד`, `ט"ו`, `ט"ז`, `י"ז`, `י"ח`, `י"ט`, `כ'`, `כ"א`, `כ"ב`, `כ"ג`, `כ"ד`, `כ"ה`, `כ"ו`, `כ"ז`, `כ"ח`, `כ"ט`, `ל'`];
const months = ['תשרי', 'חשוון', 'כסלו', 'טבת', 'שבט', 'אדר', `אדר א'`, `אדר ב'`, 'ניסן', 'אייר', 'סיוון', 'תמוז', 'אב', 'אלול'];
let _years = [];
let tens = ['', 'י', 'כ', 'ל', 'מ', 'נ', 'ס', 'ע', 'פ', 'צ']
let ones = ['', 'א', 'ב', 'ג', 'ד', 'ה', 'ו', 'ז', 'ח', 'ט']

for (let a = 6; a >= 0; a--)
    for (let b = 9; b >= 0; b--)
    {
        let year = 'תש' + tens[a] + ones[b];
        year = year.slice(0, -1) + `"` + year.slice(-1);
        _years.push(year);
    }
const years = _years;

const RoundButton = (params: { icon: IconType, onClick: React.MouseEventHandler<HTMLButtonElement>, size?: number, color?: string, style?: React.CSSProperties }) => <button className='round' onClick={params.onClick} style={{ width: params.size || 70, height: params.size || 70, fontSize: params.size! / 2 || 35, color: params.color, ...params.style }}>{<params.icon />}</button>

function App() {
  const [stage, setStage] = React.useState(0)
  const [data, setData] = React.useState<Shvush>({})
  const shvushId = React.useRef<string>()
  const [photo, setPhoto] = React.useState<File>()
  const [isLoading, setIsLoading] = React.useState<boolean>(false)
  const [isNew, setIsNew] = React.useState<boolean>()

  const setField = (key: keyof Shvush, value: string) => setData({ ...data, [key]: value })
  const next = () => {
    if (stage === 0 || stage === 7)
      setIsLoading(true)
    setStage(stage + 1)
  }
  const back = () => setStage(stage - 1)

  React.useEffect(() => {(async () => {
    switch (stage) {
      case 1:
        if (isNew !== undefined)
          break
        let result = await API.select(`/crfd1_shvushs?$filter=crfd1_mobilephone eq '${data['crfd1_mobilephone']}'`, 'single')
        setIsNew(!result)
        if (result !== undefined) {
          shvushId.current = result['crfd1_shvushid']
          setData({ ...Object.keys(result).filter(key => metadata.map(stage => stage.data).flat(1).map(field => field.key).includes(key)).reduce((obj, key) => ({ ...obj, [key]: result[key] }), {} as Shvush), crfd1_photo: undefined })
        }
        break
      case 8:
        try {
          if (isNew)
            shvushId.current = (await API.insert(`/crfd1_shvushs?$select=crfd1_shvushid`, { ...data, crfd1_id: `${data.crfd1_lastname} ${data.crfd1_firstname}`, crfd1_photo: null })).crfd1_shvushid
          else
            await API.update(`/crfd1_shvushs(${shvushId.current})`, { ...data, crfd1_id: `${data.crfd1_lastname} ${data.crfd1_firstname}`, crfd1_photo: null })
        } catch (error) {
          alert('נתקלנו בבעיה... אנא בדוק את תקינות הנתונים שהכנסת ונסה שוב')
          setStage(1)
        }
        setIsNew(false)
        data.crfd1_photo && await API.upload(`/crfd1_shvushs(${shvushId.current})/crfd1_photo`, photo!)
        await API.update(`/crfd1_shvushs(${shvushId.current})`, { statuscode: 648970007 }) // auto print
    }
    setIsLoading(false)    
  })()}, [stage])

  return <div className='App'>
    {(() => {
      if (isLoading)
        return <div className='container' style={{ width: '100vw', height: '100vh', backdropFilter: 'blur(6px)', backgroundImage: 'linear-gradient(135deg, rgba(66, 66, 66, 0.7), rgba(33, 33, 33, 0.8), rgba(55, 55, 55, 0.65))' }}>
          אנא המתן...
        </div>
      switch (stage) {
        case 0:
          return <>
            <img className='shadowedImage' src={logo} height='80px' style={{ position: 'absolute', top: '24px' }} />
            <div className='glass container'>
              <label>שאלון פרטים אישיים</label>
              <span style={{  paddingBottom: '24px', fontSize: '13px' }}>
                ברוכים הבאים לריאיון לישיבת קרית שמונה.<br />
                מקווים שאתה נהנה כאן...<br />
                <br />
                השאלון הבא ישמש אותנו לצורכי קבלה, רישום ויצירת קשר.<br />
                אנא השתדל למלא את כל הפרטים, ולדייק ככל שתוכל.<br />
                <br />
                מאחלים לך בהצלחה בכל!
              </span>
              <span style={{ textAlign: 'center', fontSize: '12px' }}>הזן את מספר הטלפון הנייד שלך:</span>
              <div className='row'>
                <input type='tel' placeholder={`טלפון נייד`} onChange={e => setField('crfd1_mobilephone', e.currentTarget.value)} /> 
              </div>
              <div className='row'>
                <button onClick={next}>התחל</button>
              </div>
            </div>
          </>
        case 8:
          return <>
            <img className='shadowedImage' src={logo} height='80px' style={{ position: 'absolute', top: '24px' }} />
            <div className='glass container' style={{ width: '80vw' }}>
              <label>תודה רבה!</label>
              הפרטים נשלחו בהצלחה...
            </div>
          </>
        default:
          return <>
            <div className='glass container' style={{ textAlign: 'center', width: '80vw', maxHeight: '80vh', overflowY: 'auto', boxSizing: 'border-box' }}>
              <label>{metadata[stage - 1].title}</label>
              {metadata[stage - 1].data.map(field => {
                switch (field.type) {
                  case 'file':
                    return <>
                      <span style={{ fontSize: '14px' }}>{field.description}</span>
                      <div className='row' key='photoInput'>
                        <input id='photoInput' type='file' accept='image/png, image/jpeg' hidden onChange={e => {
                          setField('crfd1_photo', e.currentTarget.value)
                          setPhoto(e.target.files![0])
                        }} />  
                      </div>
                      <img id='photoPreview' className='shadowedImage' src={data.crfd1_photo && URL.createObjectURL(photo!)} style={{ maxWidth: '100%', maxHeight: '50vh', marginTop: '12px', marginBottom: '12px', paddingLeft: '4px', paddingRight: '4px', borderRadius: '20px', boxSizing: 'border-box' }} hidden={!data.crfd1_photo} />
                      <div className='row' key='photoButton'>
                        <button type='submit' onClick={() => document.querySelector<HTMLInputElement>('#photoInput')?.click()}>
                          {data.crfd1_photo
                            ? 'שנה תמונה...'
                            : <div className='row'>
                              בחר תמונה...
                              {field.required && <span style={{ color: 'red' }}>*</span>}
                            </div>
                          }
                        </button>
                      </div>
                    </>
                  case 'hebdate':
                    let day = (data[field.key as keyof Shvush] as string)?.split(' ')[0] || ''
                    let month = (data[field.key as keyof Shvush] as string)?.split(' ')?.slice(1, -1)?.join(' ') || ''
                    let year = (data[field.key as keyof Shvush] as string)?.split(' ')[(data[field.key as keyof Shvush] as string)?.split(' ').length - 1] || ''
                    return <div className='container' key={field.key} style={{ width: '100%' }}>
                      <div className='row'>
                        <span style={{ fontSize: '14px', flex: 0.5 }}>
                          {field.label}
                          {field.required && <span style={{ color: 'red' }}>*</span>}
                        </span>
                        <div className='row' style={{ flex: 1 }}>
                          <select value={day} onChange={e => setField(field.key as keyof Shvush, `${e.currentTarget.value} ${month} ${year}`)} style={{ flex: 1, borderTopLeftRadius: '0px', borderBottomLeftRadius: '0px', marginLeft: '0px', marginRight: '-4px', WebkitAppearance: 'none', MozAppearance: 'none', textIndent: '1px', textOverflow: '' }}>
                            <option key={`dayNull`} value={undefined} label={'יום'} />
                            {days.map((day, index) => <option key={`day${index}`} value={day} label={day} />)}
                          </select>
                          <select value={month} onChange={e => setField(field.key as keyof Shvush, `${day} ${e.currentTarget.value} ${year}`)} style={{ flex: 1.2, borderRadius: '0px', marginLeft: '0px', marginRight: '0px', WebkitAppearance: 'none', MozAppearance: 'none', textIndent: '1px', textOverflow: '' }}>
                            <option key={`monthNull`} value={undefined} label={'חודש'} />
                            {months.map((month, index) => <option key={`day${index}`} value={month} label={month} />)}
                          </select>
                          <select value={year} onChange={e => setField(field.key as keyof Shvush, `${day} ${month} ${e.currentTarget.value}`)} style={{ flex: 1.5, borderTopRightRadius: '0px', borderBottomRightRadius: '0px', marginRight: '0px', WebkitAppearance: 'none', MozAppearance: 'none', textIndent: '1px', textOverflow: '' }}>
                            <option key={`yearNull`} value={undefined} label={'שנה'} />
                            {years.map((year, index) => <option key={`day${index}`} value={year} label={year} />)}
                          </select>
                        </div>
                      </div>
                    </div>
                  case 'textarea':
                    return <div className='container' key={field.key} style={{ width: '100%' }}>
                      <div className='row'>
                        {field.label}
                        {field.required && <span style={{ color: 'red' }}>*</span>}
                      </div>
                      <div className='row'>
                          <textarea value={data[field.key as keyof Shvush] as string || ''}  placeholder={field.description} onChange={e => setField(field.key as keyof Shvush, e.currentTarget.value)} rows={5} />
                      </div>
                    </div>
                  default:
                    return <div className='container' key={field.key} style={{ width: '100%' }}>
                      <div className='row'>
                        <span style={{ fontSize: '14px', flex: 0.5 }}>
                          {field.label}
                          {field.required && <span style={{ color: 'red' }}>*</span>}
                        </span>
                        {field.type! in selection
                          ? <select value={data[field.key as keyof Shvush] as string} onChange={e => setField(field.key as keyof Shvush, e.currentTarget.value)} style={{ flex: 1 }}>
                              <option key={`${field.key}:null`} value={undefined} label={field.description} />
                              {selection[field.type! as keyof typeof selection].map(key => <option key={`${field.key}:${key.label}`} value={key.value} label={key.label} />)}
                          </select>
                          :  <input type={field.type || 'text'} value={(() => {
                            let value = data[field.key as keyof Shvush] as string || ''
                            if (field.type === 'date')
                              value = value.substr(0, 10)
                            if (field.type === 'tel')
                              value = value.replace('-', '').replace('+972', '0')
                            return value
                          })()} placeholder={field.description} onChange={e => setField(field.key as keyof Shvush, e.currentTarget.value.replaceAll(`"`, `''`))} />}
                      </div>
                    </div>
                }
              })}
              <div className='row' style={{  paddingTop: '8px', fontSize: '11px' }}>
                השדות המסומנים ב-
                <span style={{ color: 'red', marginLeft: '3px' }}>*</span>
                הינם חובה
              </div>
            </div>
            <div className='glass row' style={{ width: '80vw', padding: '0px', boxSizing: 'border-box' }}>
              <div className='row' style={{ flex: 1, justifyContent: 'flex-start' }}>
                {stage > 1 && <RoundButton icon={MaterialIcons.MdArrowForward} size={50} onClick={back} />}
              </div>
              <div style={{ flex: 1, textAlign: 'center', paddingTop: '8px', fontSize: '14px' }}>שלב {stage} מתוך 7</div>
              <div className='row' style={{ flex: 1, justifyContent: 'flex-end' }}>
                {(() => {
                  let verified = metadata[stage - 1].data.filter(field => field.required).every(field => data[field.key as keyof Shvush]);
                  return <RoundButton icon={stage < 7 ? MaterialIcons.MdArrowBack : MaterialIcons.MdDone} size={50} onClick={() => verified ? next() : null} style={{ opacity: verified ? 1 : 0.6 }} />
                })()}
              </div>
            </div>
          </>
      }
    })()}

  </div>
}

export default App;