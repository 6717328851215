const url = 'https://yk8shvushproxy.azurewebsites.net/api/DataverseProxyAPI?code=Y0nxzxtkuWkHEDI02JdaVJ6KRPoJswUq9ChjmoYaoj07SgMmijcGJw=='

export default class APIClient {
    static select = async (query = '', resultType: 'json' | 'list' | 'single' = 'json') => {
        var options = {
            method: 'GET',
            headers: {
                'OData-MaxVersion': '4.0',
                'OData-Version': '4.0',
                'Content-Type': 'application/json; charset=utf-8'
            }
        };
        let result: any;
        try {
            await Promise.race([
                fetch(`${url}&action=select&q=${query}`, options)
                    .then(async response => {
                        result = await response.json();
                        if (response.status !== 200)
                            throw new Error('SELECT failed - ' + result.error.message);
                        return result;
                    }),
                new Promise((_, reject) => setTimeout(() => reject(new Error('timeout')), 10000))
            ]);
            switch (resultType) {
                case 'json': return Promise.resolve(result);
                case 'list': return Promise.resolve(result.value);
                case 'single': return Promise.resolve(result.value[0]);
            }
        } catch (error) {
            console.log(error);
            throw error;
        }
    }

    static insert = async (query: string, body: object) => {
        var options = {
            method: 'POST',
            headers: {
                'OData-MaxVersion': '4.0',
                'OData-Version': '4.0',
                'Content-Type': 'application/json; charset=utf-8',
                'Prefer': 'return=representation'
            },
            body: JSON.stringify(body)
        };
        try {
            return await Promise.resolve(await Promise.race([
                fetch(`${url}&action=insert&q=${query}`, options)
                    .then(async response => {
                        if (response.status !== 204 && response.status !== 201)
                            throw new Error('INSERT failed - ' + (await response.json()).error.message);
                        return await response.json();
                    }),
                new Promise<Response>((_, reject) => setTimeout(() => reject(new Error('timeout')), 10000))
            ]));
        } catch (error) {
            console.log(error);
            throw error;
        }
    }

    static update = async (query: string, body: object) => {
        var options = {
            method: 'POST',
            headers: {
                'OData-MaxVersion': '4.0',
                'OData-Version': '4.0',
                'Content-Type': 'application/json; charset=utf-8'
            },
            body: JSON.stringify(body)
        };
        try {
            return await Promise.resolve(await Promise.race([
                fetch(`${url}&action=update&q=${query}`, options)
                    .then(async response => {
                        if (response.status !== 200 && response.status !== 204)
                            throw new Error('UPDATE failed - ' + (await response.json()).error.message);
                        return response;
                    }),
                new Promise<Response>((_, reject) => setTimeout(() => reject(new Error('timeout')), 10000))
            ]));
        } catch (error) {
            console.log(error);
            throw error;
        }
    }

    static delete = async (query: string) => {
        var options = {
            method: 'POST',
            headers: {
                'OData-MaxVersion': '4.0',
                'OData-Version': '4.0',
                'Content-Type': 'application/json; charset=utf-8'
            }
        };
        try {
            return await Promise.resolve(await Promise.race([
                fetch(`${url}&action=delete&q=${query}`, options)
                    .then(async response => {
                        if (response.status !== 204)
                            throw new Error('DELETE failed - ' + (await response.json()).error.message);
                        return response;
                    }),
                new Promise<Response>((_, reject) => setTimeout(() => reject(new Error('timeout')), 10000))
            ]));
        } catch (error) {
            console.log(error);
            throw error;
        }
    }

    static upload = async (query: string, file: File) => {
        var options = {
            method: 'POST',
            headers: {
                'OData-MaxVersion': '4.0',
                'OData-Version': '4.0',
                'Content-Type': 'application/octet-stream',
                'x-ms-file-name': encodeURIComponent(file.name)
            },
            body: file
        };
        try {
            return await Promise.resolve(await Promise.race([
                fetch(`${url}&action=upload&q=${query}`, options)
                    .then(async response => {
                        if (response.status !== 204 && response.status !== 201 && response.status !== 200)
                            throw new Error('UPDATE failed - ' + (await response.json()).error.message);
                        return response;
                    }),
                new Promise<Response>((_, reject) => setTimeout(() => reject(new Error('timeout')), 10000))
            ]));
        } catch (error) {
            console.log(error);
            throw error;
        }
    }

    static download = async (query: string) => {
        var options = {
            method: 'GET',
            headers: {
                'OData-MaxVersion': '4.0',
                'OData-Version': '4.0',
                'Content-Type': 'application/octet-stream',
            }
        };
        try {
            return await Promise.resolve(await Promise.race([
                fetch(`${url}&action=download&q=${query}`, options)
                    .then(async response => {
                        if (response.status !== 204)
                            throw new Error('GET failed - ' + (await response.json()).error.message);
                        return response;
                    }),
                new Promise<Response>((_, reject) => setTimeout(() => reject(new Error('timeout')), 10000))
            ]));
        } catch (error) {
            console.log(error);
            throw error;
        }
    }
}