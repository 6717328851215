import { HTMLInputTypeAttribute } from 'react'

export type Shvush = {
    crfd1_lastname?: string | Metadata
    crfd1_email?: string | Metadata
    crfd1_mobilephone?: string | Metadata
    crfd1_school?: string | Metadata
    crfd1_class?: string | Metadata
    crfd1_city?: string | Metadata
    crfd1_firstname?: string | Metadata
    crfd1_parentsemail?: string | Metadata
    crfd1_motherjobcity?: string | Metadata
    crfd1_motherorigin?: string | Metadata
    crfd1_motherjob?: string | Metadata
    crfd1_zipcode?: string | Metadata
    crfd1_apartmentnumber?: string | Metadata
    crfd1_entrance?: string | Metadata
    crfd1_ravphone?: string | Metadata
    crfd1_fatherjob?: string | Metadata
    crfd1_origin?: string | Metadata
    crfd1_id_number?: string | Metadata
    crfd1_ravname?: string | Metadata
    crfd1_birthday?: string | Metadata
    crfd1_fatherjobcity?: string | Metadata
    crfd1_housenumber?: string | Metadata
    crfd1_fatherphone?: string | Metadata
    crfd1_fathername?: string | Metadata
    crfd1_motherphone?: string | Metadata
    crfd1_reasonofinterest?: string | Metadata
    crfd1_mothername?: string | Metadata
    crfd1_fatherorigin?: string | Metadata
    crfd1_homephone?: string | Metadata
    crfd1_hebrew_birthday?: string | Metadata
    crfd1_personsinhome?: string | Metadata
    crfd1_street?: string | Metadata
    crfd1_photo?: string | Metadata
}

type Metadata = {
    key: string
    label: string
    description?: string
    type?: HTMLInputTypeAttribute | 'hebdate' | 'class'
    required?: boolean
}

export const selection = {
    class: [
        { value: 648970000, label: 'י"א' },
        { value: 648970001, label: 'י"ב' },
        { value: 648970002, label: 'אחר' }
    ]
}

export const metadata: { title: string, data: Metadata[] }[] = [
    {
        title: 'פרטים אישיים',
        data: [
            { key: "crfd1_firstname", label: "שם פרטי", required: true },
            { key: "crfd1_lastname", label: "שם משפחה", required: true },
            { key: "crfd1_id_number", label: "מס' ת.ז.", type: 'number', required: true },
            { key: "crfd1_mobilephone", label: "טלפון נייד", type: 'tel', required: true },
            { key: "crfd1_homephone", label: "טלפון בית", type: 'tel', description: 'כולל אזור חיוג' },
            { key: "crfd1_email", label: "דוא\"ל אישי", type: 'email', required: true, description: 'לצורך רישום, נא לדייק' },
            { key: "crfd1_birthday", label: "תאריך לידה (לועזי)", type: 'date', required: true },
            { key: "crfd1_hebrew_birthday", label: "תאריך לידה (עברי)", type: 'hebdate', required: true },
            { key: "crfd1_origin", label: "ארץ לידה", required: true }
        ]
    },
    {
        title: 'העלאת תמונה',
        data: [
            { key: "crfd1_photo", label: "תמונה", type: 'file', required: true, description: 'אנא צלם / בחר תמונה ברורה ועדכנית שלך:' }
        ]
    },
    {
        title: 'פרטי מגורים',
        data: [
            { key: "crfd1_street", label: "רחוב", required: true },
            { key: "crfd1_housenumber", label: "בית", type: 'number', required: true },
            { key: "crfd1_apartmentnumber", label: "דירה", type: 'number' },
            { key: "crfd1_entrance", label: "כניסה" },
            { key: "crfd1_city", label: "עיר", required: true },
            { key: "crfd1_zipcode", label: "מיקוד", type: 'number' },
            { key: "crfd1_personsinhome", label: "מספר נפשות בבית", type: 'number', required: true }
        ]
    },
    {
        title: 'פרטי האב',
        data: [
            { key: "crfd1_fathername", label: "שם", required: true },
            { key: "crfd1_fatherphone", label: "טלפון נייד", type: 'tel', required: true },
            { key: "crfd1_fatherorigin", label: "ארץ לידה", required: true },
            { key: "crfd1_fatherjob", label: "עיסוק", description: 'במה אביך עוסק?', required: true },
            { key: "crfd1_fatherjobcity", label: "מקום עבודה", description: 'היכן אביך עובד?', required: true },
            { key: "crfd1_parentsemail", label: "דוא\"ל הורים", type: 'email', description: 'כתובת דוא"ל של אחד ההורים, לצורך שליחת עדכונים', required: true }
        ]
    },
    {
        title: 'פרטי האם',
        data: [
            { key: "crfd1_mothername", label: "שם", required: true },
            { key: "crfd1_motherphone", label: "טלפון נייד", type: 'tel', required: true },
            { key: "crfd1_motherorigin", label: "ארץ לידה", required: true },
            { key: "crfd1_motherjob", label: "עיסוק", description: 'במה אמך עוסקת?', required: true },
            { key: "crfd1_motherjobcity", label: "מקום עבודה", description: 'היכן אמך עובדת?', required: true },
            { key: "crfd1_parentsemail", label: "דוא\"ל הורים", type: 'email', description: 'כתובת דוא"ל של אחד ההורים, לצורך שליחת עדכונים', required: true }
        ]
    },
    {
        title: 'פרטי מקום הלימודים',
        data: [
            { key: "crfd1_school", label: "מקום לימודים נוכחי", required: true },
            { key: "crfd1_class", label: "כיתה", type: 'class', required: true, description: 'בחר כיתה...' },
            { key: "crfd1_ravname", label: "שם הר\"מ", required: true },
            { key: "crfd1_ravphone", label: "טלפון נייד של הר\"מ", type: 'tel', required: true }
        ]
    },
    {
        title: 'רק עוד שאלה אחת...',
        data: [
            { key: "crfd1_reasonofinterest", label: "איך שמעת עלינו?", type: 'textarea', required: true, description: 'דוגמאות:\nחברים שלי כאן\nהר"מ שלי המליץ לי\nיש לי אח/אבא שלמד כאן\nסתם רציתי לנסות...' }
        ]
    }
]